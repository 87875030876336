<template>
  <div class="search-dropdown">
    <div
      class="search-content-item"
      v-for="(item, index) in searchItems"
      :key="index"
      @click="searchCategory(item)"
    >
      <p>{{ item.title.default === "Home" ? "All" : item.title.default }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuItems: {
      type: Array
    }
  },
  data() {
    return {
      searchItems: []
    };
  },
  methods: {
    searchCategory(val) {
      // console.log("displayCategory", val.title.default);
      let sentCat = val.title.default === "Home" ? [] : val.parameters.category;
      let catObj = {
        disCat: val.title.default,
        apiCat: sentCat
      };
      this.$emit("searchCategory", catObj);
    },
    declareSearchField() {
      this.searchItems = this.menuItems.filter(el => {
        return el.isRequiredLogin === false;
      });
      // console.log("searchItems", this.searchItems);
    }
  },
  mounted() {
    this.declareSearchField();
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.search-dropdown {
  position: absolute;
  top: 105%;
  background-color: $clr-dark-gd2;
  border-radius: 10px;
  &::before {
    content: "";
    position: absolute;
    top: -0%;
    left: 14%;
    width: 0%;
    height: 0%;
    display: block;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid $clr-dark-gd2;
    z-index: -1;
  }
  .search-content-item {
    padding: 0.4rem 1rem;
    cursor: pointer;
    &:hover {
      background: $clr-dark-gd7;
    }
    &:first-child {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
</style>
