<template>
  <div class="main-search" id="search">
    <div class="suggestion-contents" v-if="showSuggestedItems">
      <ErrorAlert class="error-alert" v-if="showErrorMessage" :message="message">No Data Found</ErrorAlert>
      <div
        class="suggestion-items"
        id="suggestion-items"
        v-for="(item, i) in searchSuggestionData"
        :key="i"
        @click="searchSuggestedItem(item)"
      >
        {{ item }}
      </div>
      <!-- <div v-if="showSearchHistroy">
        <div
          class="search-history"
          id="search-history"
          v-for="(item,i) in searchHistoryData"
          :key="i"
        >
          <div
            id="history-item"
            style="width:100%;cursor:default"
            @click="searchFromHistory(item.title)"
          >{{item.title}}</div>
          <div class="delete-item" id="del-history" @click="deleteHistoryItem(item)">x</div>
        </div>
      </div>-->
      <!-- <div class="error-place" v-if="showError"></div> -->
      <!-- <ErrorAlert class="error-alert" v-if="showError" :message="message"></ErrorAlert> -->
    </div>
    <div class="search-contents" v-else>
      <!-- <div class="titled-contain">
        <div class="searched-title" v-for="(title,index) in iteratedArr.slice(0,3)" :key="index">
          <p v-if="title.length <= 15">{{title}}</p>
          <p v-if="title.length > 15">{{title.substring(0,10) + '...'}}</p>
        </div>
      </div>-->
      <div>
        <Loading class="loader" v-if="showLoader" />
      </div>
      <div class="error-place" v-if="showError">
        <!-- <div ></div> -->
      </div>
      <ErrorAlert class="error-alert" v-if="showErrorMessage" :message="message"></ErrorAlert>
      <div class="content-container" id="searched-container">
        <div
          class="content-view"
          v-for="(item, index) in mainSearchedContents"
          :key="index"
          @click="clickHandler(item)"
        >
          <categoryCard
            :key="mainSearchedContents.length"
            :content="item"
            :displayTags="false"
            :cardType="fetchCardType(item)"
            class="card-items"
          ></categoryCard>
          <p class="content-view-title" v-if="item.title.length < 15" id="searched-container-para">{{ item.title }}</p>
          <p class="content-view-title" v-else id="searched-container-para">{{ item.title.substring(0, 15) }}...</p>
          <!-- <div class="content-details">
            <p v-if="item.title.length <= 15" class="item-title">{{item.title}}</p>
            <p
              v-if="item.title.length > 15"
              class="item-title"
            >{{item.title.substring(0,15) + '...'}}</p>
            <div class="play-btn-container" @click="getDetailsAndPlay(item)">
              <img
                class="play-btn"
                src="@/assets/icons/Ic_Play1.svg"
                @click="getDetailsAndPlay(item)"
                alt
              />
              <div class="hover-container" @click="getDetailsAndPlay(item)">
                <p class="hover-container-title">{{item.title}}</p>
                <p
                  class="hover-container-duration"
                  v-if="item.duration"
                >{{calcTimeFormat(item.duration)}}</p>
              </div>
            </div>
          </div>-->
        </div>
        <p class="content-container-more" v-if="totalSearchedContent > 15" @click="seeMore">MORE RESULTS</p>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapGetters } from "vuex";

export default {
  props: ["filterCategory", "searchQuery"],
  data() {
    return {
      showSuggestedItems: true,
      showSearchHistroy: null,
      showLoader: null,
      showError: false,
      showErrorMessage: false,
      searchSuggestionData: [],
      searchedContents: [],
      iteratedArr: [],
      searchHistoryData: [],
      childCat: null,
      availabilities: [],
      subscriptions: [],
      purchases: [],
      showTitle: true,
      filteredAvailabilities: [],
      pricemodel: [],
      screenSize: screen,
      localDisplayLang: null,
      mainSearchedContents: [],
      totalSearchedContent: null,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "availabilityList", "subscriptionList", "purchaseList"]),
    // fetchCardType() {
    //   return {
    //     cardName: "Slider",
    //     type: "LANDSCAPE",
    //     height: "85px",
    //     width:'146px',
    //     playButton: { enablePlay: false }
    //   };
    // }
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    console.log("searchQuery----------->", this.searchQuery);
    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    console.log("filterCategory", this.filterCategory);
    if (this.filterCategory) {
      if (this.filterCategory.constructor === Array && this.filterCategory.length > 0) {
        this.childCat = JSON.stringify(this.filterCategory);
      }
    }

    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }
  },
  mounted() {
    document.getElementById("body").style.overflowY = "hidden";
    // eventBus.$on("loading", state => {
    //   // this.showLoader = state;
    //   this.showError = state;
    //   this.message = "";
    //   this.searchedContents = [];
    //   this.mainSearchedContents = [];
    // });
    eventBus.$on("show-error-alert", (payload) => {
      this.showErrorMessage = true;
      this.showError = false;
      this.message = this.$t(payload);
      this.iteratedArr = [];
      this.searchSuggestionData = [];
      this.searchedContents = [];
      this.mainSearchedContents = [];
      this.totalSearchedContent = null;
    });
    eventBus.$on("render-search-to-dom", this.showSearchedData);
    eventBus.$on("send-suggestions-response", this.showSuggestions);
    // this.searchHistory();
    eventBus.$on("for-direct-search", this.directSearch);
    eventBus.$on("search-suggestion-contents", () => {
      this.showErrorMessage = false;
    });
  },
  methods: {
    fetchCardType(content) {
      // console.log("content", content);

      return content.objecttype !== "ALBUM" && content.objecttype !== "CHANEL"
        ? {
            cardName: "Slider",
            type: "LANDSCAPE",
            height: "53px",
            width: "90px",
            playButton: { enablePlay: true, width: "25px", height: "25px" },
          }
        : {
            cardName: "Slider",
            type: "SQUARE",
            height: "85px",
            width: "146px",
            playButton: { enablePlay: true, width: "25px", height: "25px" },
          };
    },
    // suggestion data show
    // showSuggestions(payload) {
    //   this.searchSuggestionData = payload;
    //   this.showSearchHistroy = false;
    //   this.showErrorMessage = false;
    // },

    // search directly without suggestions
    // directSearch(payload) {
    //   console.log("search directly", payload);
    //   this.$emit("showInputText", payload.query);
    //   let hasVal = this.searchHistoryData.some(
    //     el => el.title === payload.query
    //   );
    //   // console.log("hasVal", hasVal);
    //   if (hasVal) {
    //     this.searchHistoryData;
    //   } else {
    //     this.searchHistoryData.push({ title: payload.query });
    //   }
    //   // console.log("new Search History", this.searchHistoryData);
    //   localStorage.setItem(
    //     "localSearch",
    //     JSON.stringify(this.searchHistoryData)
    //   );
    //   this.showSearchHistroy = false;
    //   this.showSuggestedItems = false;
    //   this.showErrorMessage = false;
    //   eventBus.$emit("search-api-call", payload);
    // },

    // get suggested items after typing
    // searchSuggestedItem(payload) {
    //   this.$emit("showInputText", payload);
    //   this.showSuggestedItems = false;
    //   this.showSearchHistroy = false;
    //   this.showError = false;
    //   this.showErrorMessage = false;
    //   let searchObj = {
    //     query: payload,
    //     displaylanguage: this.localDisplayLang,
    //   };
    //   let hasVal = this.searchHistoryData.some(el => el.title === payload);
    //   // console.log("hasVal", hasVal);
    //   console.log("searchObj", searchObj);
    //   eventBus.$emit("search-api-call", searchObj);
    //   if (hasVal) {
    //     return this.searchHistoryData;
    //   } else {
    //     this.searchHistoryData.unshift({ title: searchObj.query });
    //   }
    //   // console.log("new Search History", this.searchHistoryData);
    //   localStorage.setItem(
    //     "localSearch",
    //     JSON.stringify(this.searchHistoryData)
    //   );

    //   console.log("searchObj", searchObj);
    //   // eventBus.$emit("search-api-call", searchObj);
    // },

    // search History Data
    // searchHistory() {
    //   if (localStorage.getItem("localSearch")) {
    //     this.searchHistoryData = JSON.parse(
    //       localStorage.getItem("localSearch")
    //     );
    //     // this.searchHistoryData.reverse();
    //     this.showSearchHistroy = true;
    //     // console.log("searchHistoryData", this.searchHistoryData);
    //   }
    // },

    // // search from history
    // searchFromHistory(val) {
    //   let searchObj = {
    //     query: val,
    //     displaylanguage: this.localDisplayLang,
    //     category: this.childCat ? this.childCat : ""
    //   };
    //   // console.log("searched from history", searchObj);
    //   this.$emit("showInputText", searchObj.query);
    //   this.showSearchHistroy = false;
    //   this.showSuggestedItems = false;
    //   this.searchHistoryData = this.searchHistoryData.filter(el => {
    //     console.log("el", el);
    //     return searchObj.query !== el.title;
    //   });
    //   console.log("before searchHistoryData", this.searchHistoryData);
    //   let sampleObj = { title: searchObj.query };
    //   this.searchHistoryData.splice(0, 0, sampleObj);
    //   console.log("after searchHistoryData", this.searchHistoryData);
    //   localStorage.setItem(
    //     "localSearch",
    //     JSON.stringify(this.searchHistoryData)
    //   );
    //   eventBus.$emit("search-api-call", searchObj);
    // },

    // delete from history
    // deleteHistoryItem(val) {
    //   // console.log("delete item", val);
    //   this.searchHistoryData = this.searchHistoryData.filter(el => el !== val);
    //   localStorage.setItem(
    //     "localSearch",
    //     JSON.stringify(this.searchHistoryData)
    //   );
    //   // this.searchHistory();
    // },

    // show searched Data
    showSearchedData(payload) {
      this.showSuggestedItems = false;
      this.showError = false;
      this.showSearchHistroy = false;
      this.showErrorMessage = false;
      this.searchedContents = payload.data;
      this.totalSearchedContent = payload.totalcount;
      this.mainSearchedContents = this.searchedContents.filter((el) => {
        return el.objecttype !== "SEASON";
      });
      console.log("mainSearchedContents", this.mainSearchedContents);
      if (this.mainSearchedContents.length === 0) {
        this.showErrorMessage = true;
        this.message = this.$t("No Contents Found");
      }
      console.log("searched Details", this.searchedContents);
    },
    clickHandler(content) {
      eventBus.$emit("show-light-overlay", false);
      eventBus.$emit("toggle-SearchInput", false);
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (screen.width > 0) {
        // this.openDetailPopup(content);
          if(content.category == "TVSHOW"){
            //  let title;
            //   if(this.localDisplayLang == "eng"){
            //     title = content.title.replace(/\s/g, '-')
            //   }
            //   else{
            //     title = "title"
            //   }

             let title = content.defaulttitle.replace(/\s/g, "-");
            this.$router.push({ name: "detailPage", params: { contentId: content.objectid.toLowerCase() , mediaTitle:title.toLowerCase(), lang: currentLanguage  } });

          }
          else{
            //  let title;
            //   if(this.localDisplayLang == "eng"){
            //     title = content.title.replace(/\s/g, '-')
            //   }
            //   else{
            //     title = "title"
            //   }
             let title = content.defaulttitle.replace(/\s/g, "-");
            this.$router.push({ name: "detailPageMovie", params: { mediaTitle:title   , contentId: content.objectid, lang: currentLanguage  } });
          }
      } else {
        eventBus.$emit("show-light-overlay", false);
        //this.$router.push({ name: "detail", params: { contentid: content.objectid } });
      }
    },
    openDetailPopup(content) {
      //This statement to prevent click for trailers.
      if (content.contenttype && content.contenttype === "Trailer") return;

      if (
        content.objecttype !== "CONTENT" ||
        content.category === "MOVIE" ||
        (content.category === "TVSHOW" && content.objecttype !== "CONTENT")
      ) {
        let payload = {
          content: content,
          state: true,
        };
        eventBus.$emit("showContentDetail", payload);
        return;
      }
    },
    seeMore() {
      let searchData = this.searchQuery;
      console.log("more results", searchData);
      if (this.$route.name !== "SearchPage") {
        //push to search route
        this.$router.push({
          name: "SearchPage",
          params: { query: searchData },
        });
      } else {
        //already in search route,just emit the query string
        eventBus.$emit("search-query-string", searchData);
      }
    },
  },
  beforeDestroy() {
    document.getElementById("body").style.overflowY = "auto";
    eventBus.$off("loading");
    eventBus.$off("show-error-alert");
    eventBus.$off("render-search-to-dom");
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    ErrorAlert: () => import(/* webpackChunkName: "erroralert" */ "@/components/Templates/ErrorAlert.vue"),
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.main-search {
  font-family: $font-regular;
  position: absolute;
  width: 21%;
  top: 85%;
  background-color: $clr-dark-gd9;
  border-radius: 5px;
  .error-place {
    // margin: 0.8rem;
    width: 165px;
    height: 93px;
    background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
    border-radius: 8px;
  }
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: -5%;
  //   left: 8%;
  //   width: 0%;
  //   height: 0%;
  //   display: block;
  //   border-left: 1rem solid transparent;
  //   border-right: 1rem solid transparent;
  //   border-bottom: 1rem solid $clr-dark-gd2;
  //   z-index: -1;
  // }
  .suggestion-contents {
    max-height: 200px;
    overflow-y: auto;
    // padding: 0.7em;
    .suggestion-items {
      padding: 5px;
      cursor: pointer;
      font-weight: 500;
      width: fit-content;
    }
    .search-history {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 5px;
      font-weight: 500;
      &:hover {
        background-color: $clr-dark-gd4;
      }
      .delete-item {
        color: gray;
        cursor: pointer;
        z-index: 99999;
        &:hover {
          color: red;
        }
      }
    }
  }
  .titled-contain {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.4rem;
    padding: 0.8rem;
    height: 47px;
    background-color: $clr-dark-gd4;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .searched-title {
      color: $clr-light-gd4;
      font-weight: 500;
      text-align: center;
      padding: 0.2rem 0rem;
      border-right: 1px solid $clr-light-gd4;
      &:last-child {
        border-right: none;
      }
    }
  }
  .error-alert {
    height: auto;
  }
  .content-container {
    // display: flex;
    // display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-column-gap: 0.3rem;
    // padding: 0.8rem;
    max-height: 256px;
    overflow-y: auto;
    .content-view {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      border-bottom: 1px solid rgba(216, 216, 216, 0.1);
      padding: 0.5rem;
      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &:hover {
        background-color: rgba(53, 53, 53, 1);
      }
      .card {
        border-radius: 5px;
      }
      &-title {
        padding: 0.5em;
      }
    }
    &-more {
      text-align: center;
      padding: 0.2rem 0rem;
      font-size: 0.8rem;
      cursor: pointer;
    }
    // .content-view {
    //   z-index: 9999999;
    // }
    //   padding: 5px;
    //   position: relative;
    //   .content-details {
    //     margin: 5px;
    //     border-radius: 8px;
    //     position: absolute;
    //     top: 0%;
    //     bottom: 0%;
    //     right: 0%;
    //     left: 0%;
    //     background-image: linear-gradient(
    //       to bottom,
    //       rgba(0, 0, 0, 0.02) 0%,
    //       rgba(0, 0, 0, 0.5) 90%
    //     );
    //     &:hover {
    //       background-image: linear-gradient(
    //         to bottom,
    //         rgba(0, 0, 0, 0.02) 0%,
    //         rgba(0, 0, 0, 0.8) 90%
    //       );
    //       transition: all 0.4s ease-in-out;
    //       .play-btn-container {
    //         cursor: pointer;
    //         opacity: 1;
    //         transition: all 0.4s ease-in-out;
    //       }
    //       .item-title {
    //         opacity: 0;
    //       }
    //     }
    //     .item-title {
    //       color: white;
    //       position: absolute;
    //       bottom: 11%;
    //       opacity: 1;
    //       left: 8%;
    //     }
    //     .play-btn-container {
    //       position: absolute;
    //       top: 0%;
    //       bottom: 0%;
    //       left: 0%;
    //       right: 0%;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       opacity: 0;
    //       .play-btn {
    //         width: 20px;
    //       }
    //       .hover-container {
    //         position: absolute;
    //         bottom: 6%;
    //         left: 7%;
    //         &-title {
    //           color: white;
    //           padding-bottom: 0.3em;
    //         }
    //         &-duration {
    //           font-weight: normal;
    //           font-size: 0.8em;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
@media only screen and (max-width: 999px) {
  .main-search {
    width: 90% !important;
  }
}
</style>
